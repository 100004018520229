import React, { useState } from "react";
import axios from "axios";

import "../styles/centered-box.less";
import "../styles/offres.less";

import { getLocalFormValues, navigate } from "../utils";

import { Layout, Row, Col, Form, Input, Button, Radio, message } from "antd";
import { NumberOutlined, EuroOutlined } from "@ant-design/icons";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

function submitAnswer(newFormValues, setIsLoading) {
  setIsLoading(true);
  const { request_id: requestId } = newFormValues;
  localStorage.setItem(requestId, JSON.stringify(newFormValues));
  axios
    .post(process.env.GATSBY_API_URL + "/update-registration", newFormValues)
    .then((response) => {
      let path;
      switch (true) {
        case newFormValues.vehicle_speed !== "25":
          path = "/offre-non-disponible";
          break;
        case parseFloat(newFormValues.vehicle_price) > 8000:
          path = "/prix-du-vehicule-trop-eleve";
          break;
        case parseFloat(newFormValues.vehicle_price) < 50:
          path = "/prix-du-vehicule-trop-faible";
          break;
        default:
          path = "/form3-address";
          break;
      }
      navigate(`${path}#${requestId}`);
    })
    .catch((e) => {
      message.error("Erreur lors de la communication : " + e.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
}

export default function SpeedForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const initFormValues = getLocalFormValues();

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    return navigate("/");
  }

  const {
    request_id: requestId,
    firstname,
    vehicle_type,
    vehicle_brand,
    vehicle_model,
    vehicle_price,
    vehicle_immat,
  } = initFormValues;

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>
              Merci {firstname}, dites-nous en plus sur votre{" "}
              {(vehicle_type || "").toLowerCase()}...
            </h2>
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) =>
                submitAnswer(
                  {
                    ...initFormValues,
                    ...values,
                  },
                  setIsLoading
                )
              }
            >
              <Row type="flex" justify="center" gutter={32}>
                <Col span={24}>
                  <Form.Item
                    name="vehicle_speed"
                    label={
                      <p style={{ textAlign: "center", marginBottom: 10 }}>
                        Quelle est sa vitesse maximale ?
                      </p>
                    }
                    initialValue="25"
                  >
                    <Radio.Group disabled={isLoading} buttonStyle="solid">
                      <Radio.Button value="25">
                        25km/h ou inférieur
                      </Radio.Button>
                      <Radio.Button value="30">Supérieur à 25km/h</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col xl={5} sm={24}>
                  <Form.Item
                    name="vehicle_brand"
                    label="Sa marque"
                    initialValue={vehicle_brand}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLoading}
                      type="text"
                      placeholder="La marque"
                    />
                  </Form.Item>
                </Col>
                <Col xl={5} sm={24}>
                  <Form.Item
                    name="vehicle_model"
                    label="Son modèle"
                    initialValue={vehicle_model}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                  >
                    <Input
                      disabled={isLoading}
                      type="text"
                      placeholder="Le modèle"
                    />
                  </Form.Item>
                </Col>

                <Col xl={5} sm={24}>
                  <Form.Item
                    name="vehicle_immat"
                    initialValue={vehicle_immat}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                    label="Son numéro de série"
                  >
                    <Input
                      addonBefore={<NumberOutlined />}
                      type="text"
                      placeholder="Ex: 12BN24"
                      disabled={isLoading}
                    />
                  </Form.Item>
                </Col>

                <Col xl={5} sm={24}>
                  <Form.Item
                    name="vehicle_price"
                    initialValue={vehicle_price}
                    rules={[
                      {
                        required: true,
                        message: "Requis",
                      },
                    ]}
                    label="Son prix"
                  >
                    <Input
                      addonBefore={<EuroOutlined />}
                      type="number"
                      min={0}
                      placeholder="Ex: 150€"
                      disabled={isLoading}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Button
                    style={{ marginTop: 30, marginRight: 15 }}
                    onClick={() => navigate(`/form1-identity#${requestId}`)}
                    disabled={isLoading}
                  >
                    Retour
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Plus que 2 clics
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
